.copywrites {
  text-align: center;
  font-size: 10px;
  padding: 5px;
  border-top: 1px solid #eee;
}

.copywrites a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

.copywritesBlack {
  background-color: #0f0f0f;
  border: 0;
  color: #ccc;
}

.copywritesBlack a {
  color: #f7f7f7;
}
