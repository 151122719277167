@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

.totals {
  border: 1px solid #1976d2;
  text-align: center;
  max-width: 130px;
  border-radius: 10px;
  min-width: auto;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  color: #1976d2;
  font-size: 30px;
  margin-top: 40px;
  box-shadow: 0px 0px 5px 0px #1976d2;
  font-family: "Inter";
}
.noresults {
  margin: 50px 0;
  background: #d6ebff;
  padding: 10px;
  border-radius: 4px;
}
.tabs {
  margin-top: 20px;
}
.parterSum {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.parterSum span {
  margin: 15px 15px 0;
  padding: 5px 20px;
  border-radius: 4px;
}

.personal {
  background-color: #b1d8ff;
}
.partner {
  background-color: antiquewhite;
}
