.header {
  background-color: #1976d2;
  padding: 15px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 0px 15px black;
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  /* margin-top: 38px !important; */
}

.settings,
.logout {
  padding: 0 10px 0 0 !important;
}

.logout {
  margin-left: 10px !important;
}

.settings svg {
  fill: white;
}
.header a {
  margin-left: auto;
  text-decoration: none;
}
.header a:first-child {
  margin-left: initial;
}
.logout svg {
  fill: white;
}
.logo {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: #fff;
  text-decoration: inherit;
}
.rightSide {
  margin-left: auto;
  text-align: right;
}
.rightSide label span {
  margin: 0;
}
.rightSide label {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 18px;
}

.topUpper {
  display: flex;
  align-items: baseline;
}

@media screen and (min-width: 700px) {
  .header {
    max-width: 900px;
    margin: auto;
    /* margin-top: 50px; */
  }
}
