.title {
  color: #fff !important;
  background-color: #1976d2;
  text-align: center;
  padding: 5px 20px !important;
  min-width: 200px;
  border-radius: 4px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title svg {
  margin-left: 0px;
  width: 16px;
  fill: #fff;
}
