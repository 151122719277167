.wrapper {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
}
.pickers {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
}
.predifinedPeriods {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.predifinedPeriods button {
  font-size: 12px;
}

.pickers > div:first-child {
  margin-right: 20px;
}

.pickers input {
  height: 10px;
}

.smallButtons button {
  font-size: 9px;
}

@media screen and (min-width: 620px) {
  .predifinedPeriods {
    max-width: 450px;
    margin: auto;
    margin-top: 10px;
  }
  .predifinedPeriods > button {
    margin-right: 15px;
  }
  body .smallButtons button {
    font-size: 12px !important;
  }
}
