.table td {
  max-width: 100px;
}
.switcher span {
  font-size: 11px;
  text-align: center;
}
.switcher {
  display: flex;
  flex-direction: column;
}
.leftSide {
  display: flex;
  flex-direction: column;
}
