.title {
  color: #fff !important;
  background-color: #1976d2;
  text-align: center;
  padding: 10px 20px !important;
  min-width: 200px;
  border-radius: 4px;
  margin-top: 25px;
}
.listBlack {
  color: #fff;
}
.mine {
  background-color: #b1d8ff;
  padding: 0px 10px;
  margin-right: 10px;
  border-radius: 4px;
  color: #000;
}
.partner {
  background-color: #faebd7;
  padding: 0px 10px;
  border-radius: 4px;
  color: #000;
}
.icon {
  min-width: 35px !important;
}
.analysisPartner {
  display: flex;
  justify-content: center;
}
.listName span {
  max-width: 140px;
}
