body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: auto;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container__page {
  padding-bottom: calc(10vh - 50px);
}

.donate_wrapper {
  /* position: fixed; */
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 0 5px 1px #000;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
}
.donate_wrapper button {
  /* font-size: 11px; */
  margin: 5px;
}

.daypick .MuiPickersToolbar-root,
.daypick .MuiPickersCalendarHeader-root {
  display: none !important;
}

.MuiAccordion-root.Mui-expanded {
  border: 2px solid #1976d2;
}
.MuiAccordion-root.Mui-expanded > div:first-child {
  border-bottom: 1px solid #eee;
  background-color: #f0f8ff;
  margin-bottom: 10px;
}

.app_wrapper_dark .MuiAccordion-root.Mui-expanded > div:first-child {
  background-color: #02366a;
  border-bottom: 0;
}

@media screen and (min-width: 700px) {
  .donate_wrapper button {
    font-size: 16px !important;
  }
  .container__page {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    min-height: 73vh;
  }

  .app_wrapper {
    height: auto;
    padding: 0 !important;
  }

  .bottom__nav {
    max-width: 900px;
    margin: auto;
  }
  body {
    background-image: url(https://mycashtrack.com/bg.png);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    z-index: -1;
    bottom: 0;
  }
  .loginform {
    background-color: #fff !important;
    padding: 20px;
    margin-top: 50px;
  }
}
