.headerTitle {
  padding: 0px;
}

.headerCheckBox {
  padding-right: 0px;
  margin-right: -10px;
}

.listtext span {
  font-size: 12px !important;
}

.chosen {
  background-color: #d4f4f5;
}
.chosenDark {
  background-color: #16797c;
}

.notchosen {
  background-color: #ffeded;
}

.notchosenDark {
  background-color: #c57d7d;
}

@media screen and (min-width: 700px) {
  .list {
    width: 300px !important;
  }
}

@media screen and (max-width: 370px) {
  .list {
    width: 300px !important;
  }
  .buttons {
    width: 100% !important;
    margin-bottom: -30px !important;
  }
  .buttons > div {
    display: revert;
    text-align: center;
  }
  .buttonright {
    transform: rotate(90deg);
    margin-right: 30px !important;
    height: 80px;
  }
  .buttonleft {
    transform: rotate(90deg);
    height: 80px;
    margin-left: 74px !important;
  }
}
