.title {
  color: #fff !important;
  background-color: #1976d2;
  text-align: center;
  padding: 10px 20px !important;
  min-width: 200px;
  border-radius: 4px;
}

.radioList {
  padding-left: 15px;
  max-height: 250px;
  overflow: auto;
  display: flex !important;
  flex-wrap: nowrap !important;
}

.hidden {
  display: none !important;
}
.radioListBlack {
  background-color: #1e1e1e;
  color: #fff;
}
