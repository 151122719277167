.saveButton {
  margin-top: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  margin-bottom: 30px !important;
}

.wrapper {
  margin-top: 25px;
}

.more {
  background-color: #0a4681;
  padding: 0 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.more button {
  color: #fff;
  font-size: 17px;
  display: flex;
  flex-wrap: wrap;
}

.more button span {
  font-size: 11px;
  width: 100%;
}

.more button svg {
  position: absolute;
  right: 0;
}
.moreSettingVisible {
  transform: rotate(180deg);
}

.recurWrapper {
  width: 100%;
}

.recurWrapper .MuiAccordion-root.Mui-expanded {
  border: 2px solid #1976d2;
}
