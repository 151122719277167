.progressBars {
  height: 10px !important;
  border-radius: 4px;
}
.overlimit,
.overlimit span {
  background-color: red !important;
}
.reachlimit span {
  background-color: #fbb10a !important;
}
.message {
  color: #333;
  margin-top: 10px;
  font-size: 14px;
}
.messageBlack {
  color: #fff;
}
.exludedSum {
  font-size: 14px;
  border: 1px solid;
  margin-top: 10px;
  padding: 5px;
  border-style: dashed;
}
.exludedSumBlack {
  color: #fff;
}

.economyAchivied span {
  background-color: green !important;
}
.exludeMessage {
  border: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  border-style: dashed;
}
.netWorthPartnerError {
  color: #fff;
  font-size: 12px;
}
