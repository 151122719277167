.wrapper {
  margin-top: 20px;
}
.actionBar {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.textarea {
  min-width: 220px;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px;
}

.textareaBlack {
  background-color: #0f0f0f;
  color: #fff;
}

.trackCheckbox {
  max-width: 265px;
  display: flex;
  align-items: center;
  color: #0f0f0f;
  font-size: 13px;
}
.trackCheckboxBlack {
  color: #fff;
}
