.recurringWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.recurringWrapper > div {
  width: 300px;
  padding-bottom: 20px;
}

.accordionSummary {
  padding-right: 5px !important;
}

.active {
  margin-left: auto;
  font-size: 14px;
  background-color: green;
  padding: 1px 15px;
  border-radius: 4px;
  color: #fff;
  margin-right: 10px;
}

.inactive {
  margin-left: auto;
  font-size: 14px;
  background-color: #d32f2f;
  padding: 1px 15px;
  border-radius: 4px;
  color: #fff;
  margin-right: 10px;
  min-width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionsUpdate {
  display: flex;
  justify-content: space-around;
}

.button {
  margin-bottom: 50px !important;
}

.dayselected input {
  text-align: center;
}
